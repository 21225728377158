import React, { useEffect, useState, useCallback } from "react";
import Layout from "../../layout/Layout";
import ImageCard from "../../components/Cards/ImageCard";
import VideoCard from "../../components/Cards/VideoCard";
import TabsLayout from "../../components/TabsLayout/TabsLayout";
import { manageScreenShots } from "../../redux/actions/manageScreenshots";
import { connect, useDispatch } from "react-redux";
import { manageVideos } from "../../redux/actions/manageVideos";
import NotFound from "../../components/NotFound/NotFound";
import Loader from "../../components/Loader/Loader";
import { checkServerURL, sg_handle_multiselect } from "../../helper/helper";
import { API_URL, localStoragePrefix } from "../../utils/config";
import { getAllSavedSnippets } from "../../redux/actions/manageSavedSnippets";
import SnippetCard from "../../components/Cards/SnippetCard";
import AdSenseAd from "../../components/Adsense/adSenseAd";

const Dashboard = ({
  screenShorts,
  notFoundScreenShorts,
  videos,
  notFoundVideos,
  loadingScreenshorts,
  loadingVideos,
  snippetsDetails,
  loadingSnippets,
  notFoundSnippets,
}) => {
  const dispatch = useDispatch();
  const userID = localStorage.getItem(localStoragePrefix + "screenGeniusUserID");
  const accessToken = localStorage.getItem(localStoragePrefix + "screenGeniusAuthToken");
  useEffect(() => {
    if (userID && accessToken) {
      dispatch(manageScreenShots(Number(userID), accessToken));
      dispatch(manageVideos(Number(userID), accessToken));
      const payload = {
        user_id: Number(userID),
        access_token: accessToken,
      };
      dispatch(getAllSavedSnippets(payload));
    }
  }, [dispatch, userID]);
  // get screenshort images
  const screenShortsData = screenShorts?.data && screenShorts?.data;
  // get videos images
  const videosData = videos?.data && videos?.data;
  // saved snippets list data
  const snippetsData = snippetsDetails?.data && snippetsDetails?.data;

  // multiselect screenshot
  const [selectedScreenshotIDs, setSelectedScreenshotIDs] = useState([]);
  const [selectedVideoIDs, setSelectedVideoIDs] = useState([]);
  const [selectedSnippetIDs, setSelectedSnippetIDs] = useState([]);

  const [isActiveTab, setActiveTab] = useState("quix-tab-images");
  // multiple select screenshots & videos
  const handleMultipleSelect = (e, type) => {
    const checked = e.target.checked;
    if (type === 'screenshot') {
      if (checked) {
        let screenshotIDs = screenShortsData?.filter(items => items?.id)?.map(el => el.id) || [];
        setSelectedScreenshotIDs(screenshotIDs);
      } else {
        setSelectedScreenshotIDs([]);
      }
    } else if (type === 'video') {
      if (checked) {
        let VideoIDs = videosData?.filter(items => items?.id)?.map(el => el.id) || [];
        setSelectedVideoIDs(VideoIDs);
      } else {
        setSelectedVideoIDs([]);
      }
    } else if (type === 'snippets') {
      if (checked) {
        let snippetIDs = snippetsData?.filter(items => items?.id)?.map(el => el.id) || [];
        setSelectedSnippetIDs(snippetIDs);
      } else {
        setSelectedSnippetIDs([]);
      }
    }
  }

  // save audio manage
  const [currentAudio, setCurrentAudio] = useState(null);
  const [currentAudioIndex, setCurrentAudioIndex] = useState(-1);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = useCallback(
    (index, audio) => {
      const audioURL = audio ? checkServerURL(API_URL, audio) : null;
      if (!audioURL) {
        console.error("No audio URL provided");
        return;
      }
      // reset audio time and button status
      const resetAudio = (audio) => {
        audio.currentTime = 0;
        setIsPlaying(false);
      };
      if (index !== currentAudioIndex) {
        if (currentAudio) {
          currentAudio.pause();
          currentAudio.currentTime = 0;
        }
        const newAudio = new Audio(audioURL);
        newAudio.play();
        setCurrentAudio(newAudio);
        setCurrentAudioIndex(index);
        setIsPlaying(true);
        newAudio.onended = () => resetAudio(newAudio);
      } else {
        if (isPlaying) {
          currentAudio.pause();
          currentAudio.currentTime = 0;
          currentAudio.onended = () => resetAudio(currentAudio);
        } else {
          currentAudio.play();
          currentAudio.onended = () => resetAudio(currentAudio);
        }
        setIsPlaying(!isPlaying);
      }
    },
    [currentAudio, currentAudioIndex, isPlaying]
  );

  return (
    <Layout page={"dashboard"}>
      <div id="quix-dashboard-content">
        <div className="addsense float-left">
          <AdSenseAd
            client="ca-pub-4424794709901498"
            slot="8328007263"
            format="auto"
          />
        </div>
        <TabsLayout
          selectedScreenshotIDs={selectedScreenshotIDs}
          selectedVideoIDs={selectedVideoIDs}
          selectedSnippetIDs={selectedSnippetIDs}
          isActivePage={"dashboard"}
          isActiveTab={setActiveTab}
        >
          {/*Dashboard Images Content */}
          <div
            className={`tab-pane fade ${isActiveTab === "quix-tab-images" ? "show active" : ""
              }`}
            id="screen-genius-images"
            role="tabpanel"
            aria-labelledby="quix-tab-images"
          >
            <div className="quix-dashboard-content-images">
              {!notFoundScreenShorts && screenShortsData && (
                <div className="quix-multi-select form-check mx-4 mb-3">
                  <input onChange={(e) => handleMultipleSelect(e, 'screenshot')} className="form-check-input" name="multi-screenshot-select" type="checkbox" value="" id="multi-select-screenshot" />
                  <label className="form-check-label" htmlFor="multi-select-screenshot">
                    Select all Screenshots.
                  </label>
                </div>
              )}
              <div
                className={`${loadingScreenshorts ? "quix-data-loading" : ""}`}
              >
                {loadingScreenshorts ? (
                  <Loader />
                ) : !notFoundScreenShorts && screenShortsData ? (
                  <div className={`quix-dashboard-content-innr`}>
                    {screenShortsData &&
                      screenShortsData?.map((items, ind) => {
                        return (
                          <ImageCard
                            key={ind}
                            cardData={items}
                            isCheckedCard={selectedScreenshotIDs?.includes(
                              items?.id
                            )}
                            handleChangeChecked={(id) =>
                              sg_handle_multiselect(
                                id,
                                setSelectedScreenshotIDs
                              )
                            }
                          />
                        );
                      })}
                  </div>
                ) : (
                  <NotFound />
                )}
              </div>
            </div>
          </div>
          {/*Dashboard Videos Content */}
          <div
            className={`tab-pane fade ${isActiveTab === "quix-tab-videos" ? "show active" : ""
              }`}
            id="screen-genius-videos"
            role="tabpanel"
            aria-labelledby="quix-tab-videos"
          >
            <div className="quix-dashboard-content-videos">
              {!notFoundVideos && videosData && (
                <div className="quix-multi-select form-check mx-4 mb-3">
                  <input onChange={(e) => handleMultipleSelect(e, 'video')} className="form-check-input" name="multi-video-select" type="checkbox" value="" id="multi-select-video" />
                  <label className="form-check-label" htmlFor="multi-select-video">
                    Select all Screen Recordings.
                  </label>
                </div>
              )}
              <div className={`${loadingVideos ? "quix-data-loading" : ""}`}>
                {loadingVideos ? (
                  <Loader />
                ) : !notFoundVideos && videosData ? (
                  <div className="quix-dashboard-content-innr">
                    {videosData &&
                      videosData?.map((items, ind) => {
                        return (
                          <VideoCard
                            key={ind}
                            cardData={items}
                            isCheckedCard={selectedVideoIDs?.includes(
                              items?.id
                            )}
                            handleChangeChecked={(id) =>
                              sg_handle_multiselect(id, setSelectedVideoIDs)
                            }
                          />
                        );
                      })}
                  </div>
                ) : (
                  <NotFound />
                )}
              </div>
            </div>
          </div>
          <div
            className={`tab-pane fade ${isActiveTab === "quix-tab-save-snippets" ? "show active" : ""
              }`}
            id="ocr-save-snippets"
            role="tabpanel"
            aria-labelledby="quix-tab-save-snippets"
          >
            <div className="quix-dashboard-content-snippets">
              <div className="quix-dashboard-content-images">
                {!notFoundSnippets && snippetsData && (
                  <div className="quix-multi-select form-check mx-4 mb-3">
                    <input onChange={(e) => handleMultipleSelect(e, 'snippets')} className="form-check-input" name="multi-snippets-select" type="checkbox" value="" id="multi-select-snippets" />
                    <label className="form-check-label" htmlFor="multi-select-snippets">
                      Select all Snippets.
                    </label>
                  </div>
                )}
                <div
                  className={`${loadingSnippets ? "quix-data-loading" : ""}`}
                >
                  {loadingSnippets ? (
                    <Loader />
                  ) : !notFoundSnippets && snippetsData ? (
                    <div className={`quix-dashboard-content-innr`}>
                      {snippetsData &&
                        snippetsData?.map((items, ind) => {
                          return (
                            <SnippetCard
                              key={ind}
                              cardDataInd={ind}
                              cardData={items}
                              currentAudioIndex={currentAudioIndex}
                              isPlaying={isPlaying}
                              handlePlayPause={handlePlayPause}
                              isCheckedCard={selectedSnippetIDs?.includes(
                                items?.id
                              )}
                              handleChangeChecked={(id) =>
                                sg_handle_multiselect(
                                  id,
                                  setSelectedSnippetIDs
                                )
                              }
                            />
                          );
                        })}
                    </div>
                  ) : (
                    <NotFound />
                  )}
                </div>
              </div>
            </div>
          </div>
        </TabsLayout>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    loadingScreenshorts: state?.screen_shorts?.is_loading,
    screenShorts: state?.screen_shorts?.list,
    notFoundScreenShorts: state?.screen_shorts?.not_found,
    loadingVideos: state?.screen_shorts?.is_loading,
    videos: state?.videos?.list,
    notFoundVideos: state?.videos?.not_found,
    snippetsDetails: state?.saved_snippets?.list,
    loadingSnippets: state?.saved_snippets?.is_loading,
    notFoundSnippets: state?.saved_snippets?.not_found,
  };
};

export default connect(mapStateToProps, { manageScreenShots, manageVideos, getAllSavedSnippets })(
  Dashboard
);
